import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Quick_Installation/NavButtons';
import ForumBox from 'components/Quick_Installation/How_To_Clear_Your_Browsing_History/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Erase your Browsing History in Vivaldi",
  "path": "/Quick_Installation/How_To_Clear_Your_Browsing_History/Vivaldi/",
  "dateChanged": "2017-11-24",
  "author": "Mike Polinowski",
  "excerpt": "Erase your Browsing History in Vivaldi",
  "image": "./QI_SearchThumb_ClearBrowsingHistory_Vivaldi.png",
  "social": "/images/Search/QI_SearchThumb_ClearBrowsingHistory_Vivaldi.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_QI-Clear_History_white.webp",
  "chapter": "Installation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Clear your Browser History' dateChanged='2017-11-24' author='Mike Polinowski' tag='INSTAR IP Camera' description='Clear your Browser History' image='/images/Search/QI_SearchThumb_ClearBrowsingHistory.png' twitter='/images/Search/QI_SearchThumb_ClearBrowsingHistory.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Schnell_Installation/Leeren_des_Browserverlaufs/Vivaldi/' locationFR='/fr/Quick_Installation/How_To_Clear_Your_Browsing_History/Vivaldi/' crumbLabel="Vivaldi" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "erase-your-browsing-history-in-vivaldi",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#erase-your-browsing-history-in-vivaldi",
        "aria-label": "erase your browsing history in vivaldi permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Erase your Browsing History in Vivaldi`}</h1>
    <p>{`Sometimes your camera´s WebUI might not behave the way you want. You updated your camera´s software, but it still shows you the old version? An update should bring new functions to the user interface - but they are nowhere to be seen? Or you simply cannot change a setting? Your Browser might have cached old parts of the user interface and refuses to reload the new or updated files. Here is how you can get rid of your browsers browsing history in several common browsers:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ee1da2a171d98ababc1e73db1175d936/5a190/Vivaldi-Clean-History_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "21.304347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAh0lEQVQI142NsQrCQBBE7/+/RCw1UcRCSOEnaCcqpBBUjMnsnuvtjSSFICj4mGpg5oX5aEwRnC/WwWI00QckQtpbg+ZuyWW3R7WOKWsnCh0iaAHRUCyWJE+b7bWuJcacMweSu6dE0g9HW1V95c5PwrQoSYoqgKfZe/wPYVLOes8g+U7O/PH4AqTA5xHf9saNAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ee1da2a171d98ababc1e73db1175d936/e4706/Vivaldi-Clean-History_01.avif 230w", "/en/static/ee1da2a171d98ababc1e73db1175d936/d1af7/Vivaldi-Clean-History_01.avif 460w", "/en/static/ee1da2a171d98ababc1e73db1175d936/c9875/Vivaldi-Clean-History_01.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ee1da2a171d98ababc1e73db1175d936/a0b58/Vivaldi-Clean-History_01.webp 230w", "/en/static/ee1da2a171d98ababc1e73db1175d936/bc10c/Vivaldi-Clean-History_01.webp 460w", "/en/static/ee1da2a171d98ababc1e73db1175d936/d00b9/Vivaldi-Clean-History_01.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ee1da2a171d98ababc1e73db1175d936/81c8e/Vivaldi-Clean-History_01.png 230w", "/en/static/ee1da2a171d98ababc1e73db1175d936/08a84/Vivaldi-Clean-History_01.png 460w", "/en/static/ee1da2a171d98ababc1e73db1175d936/5a190/Vivaldi-Clean-History_01.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ee1da2a171d98ababc1e73db1175d936/5a190/Vivaldi-Clean-History_01.png",
              "alt": "Erase your Browsing History in Vivaldi",
              "title": "Erase your Browsing History in Vivaldi",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Open a new Browser Tab, click on History and Clear Browsing Data.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a98f90f4fc9a5e17dd51ee13bb78e935/5a190/Vivaldi-Clean-History_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.608695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAo0lEQVQoz5XQzQ6CMAzAcd7/kXgGOcGZ4QHEQLKMNquu3WrAqAengf9pyfrLPopTVRlj5nmu67osy6Zppmkax/H6t34YemMKVQ0hpC3d3XN4xcycUoqv0s5i/OCvrRhVk2rc1lmewSICiOQ9DRfsOvE+MGd9BjOzcw4B7wABIInwfizMiEg3en8PHzqZiNariixt67qzJzrwZiLyW2DtYi39wA9wCQ4dIdfyOgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a98f90f4fc9a5e17dd51ee13bb78e935/e4706/Vivaldi-Clean-History_02.avif 230w", "/en/static/a98f90f4fc9a5e17dd51ee13bb78e935/d1af7/Vivaldi-Clean-History_02.avif 460w", "/en/static/a98f90f4fc9a5e17dd51ee13bb78e935/c9875/Vivaldi-Clean-History_02.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a98f90f4fc9a5e17dd51ee13bb78e935/a0b58/Vivaldi-Clean-History_02.webp 230w", "/en/static/a98f90f4fc9a5e17dd51ee13bb78e935/bc10c/Vivaldi-Clean-History_02.webp 460w", "/en/static/a98f90f4fc9a5e17dd51ee13bb78e935/d00b9/Vivaldi-Clean-History_02.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a98f90f4fc9a5e17dd51ee13bb78e935/81c8e/Vivaldi-Clean-History_02.png 230w", "/en/static/a98f90f4fc9a5e17dd51ee13bb78e935/08a84/Vivaldi-Clean-History_02.png 460w", "/en/static/a98f90f4fc9a5e17dd51ee13bb78e935/5a190/Vivaldi-Clean-History_02.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a98f90f4fc9a5e17dd51ee13bb78e935/5a190/Vivaldi-Clean-History_02.png",
              "alt": "Erase your Browsing History in Vivaldi",
              "title": "Erase your Browsing History in Vivaldi",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Check the options above and click Clear Browsing Data.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      